import $vuetify from 'vuetify/es5/locale/es'

export default {
  generic: {
    ok: 'Ok',
    no: 'No',
    yes: 'Sí',
    save: 'Guardar',
    saved: 'Guardado',
    saving: 'Guardando',
    saveNow: 'Guardar ahora',
    loading: 'Cargando',
    success: 'Éxito',
    error: 'Error',
    errorMsg: 'Algo no funcionó. Por favor, intenta de nuevo...',
    cancel: 'Cancelar',
    search: 'Búsqueda',
    searchNoResults: "No se hallaron resultados para '{search}'",
    update: 'Actualizar',
    remove: 'Eliminar',
    submit: 'Enviar',
    gotIt: '¡Entendido!',
    apply: 'Enviar',
    menu: 'Menú',
    back: 'Volver',
    more: 'Más',
    none: 'Nada',
    close: 'Cerrar',
    route: 'Ruta',
    routes: 'Rutas',
    thisRoute: 'esta ruta',
    climbing: 'Escalando',
    rope: 'Cuerda',
    boulder: 'Bloque',
    boulders: 'Bloques',
    thisBoulder: 'este bloque',
    bouldering: 'Haciendo bloque',
    settings: 'Configuración',
    readMore: 'Leer más',
    details: 'Detalles',
    today: 'Hoy',
    tomorrow: 'Mañana',
    total: 'Total',
    copied: 'Copiado',
    comingSoon: 'Próximamente',
    linkCopiedMsg: 'Enlace copiado a portapapeles',
    noData: 'Información no disponible',
    refresh: 'Recargar',
    offlineMsg: 'Estás desconectado.',
    offlineMsgSave: 'Los cambios se guardarán cuando vuelvas a conectarte.',
    offlineMsgNotSaved: 'Tus cambios no se guardarán.',
    badConnection: 'Problemas de conexión',
    pagination: {
      frst: 'Primera página',
      prev: 'Página anterior',
      next: 'Página siguiente',
      last: 'Última página',
    },
  },
  inputRules: {
    required: 'Requerido',
    minLength: 'Debe tener al menos {length} caracteres',
    maxLength: 'Debe tener como máximo {length} caracteres',
    minVal: 'Debe ser al menos {minVal}',
    maxVal: 'Debería ser como máximo {maxVal}',
    emailInvalid: 'Correo electrónico no válido',
    shouldMatchPwd: 'Debería coincidir con la contraseña',
    dateInvalid: 'Fecha invalida',
    timeInvalid: 'Hora inválida',
  },
  sidenav: {
    profilePic: 'Foto de perfil',
    withoutName: 'Usuario sin nombre',
    atGym: 'En {gymName}',
    profile: 'Perfil',
    myProfile: 'Mi perfil',
    switchGym: 'Cambiar de rocódromo',
    selectGym: 'Seleccionar rocódromo',
    signOut: 'Cerrar sesión',
    signIn: 'Abrir sesión',
    closeApp: 'Cerrar aplicación',
    routes: 'Rutas',
    boulders: 'Bloques',
    dashboard: 'Tablero',
    competitions: 'Competiciones',
    myGym: 'Mi rocódromo',
    reservations: 'Reservaciones',
    inbox: 'Bandeja de entrada',
    editRoutes: 'Editar rutas',
    editBoulders: 'Editar bloques',
    editCompetitions: 'Editar competiciones',
    communications: 'Comunicados',
    statistics: 'Estadísticas',
    gym: 'Rocódromo',
    monitoring: 'Monitorizar',
    help: 'Ayuda',
    aboutUs: 'Sobre nosotros',
    contact: 'Contacto',
    sendFeedback: 'Envíanos tu opinión',
    toc: 'Términos y condiciones',
  },
  auth: {
    loginTitle: 'Iniciar sesión | TopLogger',
    loginDescription: 'Abre tu sesión de TopLogger o crea una cuenta.',
    resetPwdTitle: 'Restablece tu contraseña | TopLogger',
    resetPwdDescription: 'Restablece tu contraseña para TopLogger',
    unauthorized: 'No autorizado',
    login: 'Iniciar sesión',
    signIn: 'Registrarme',
    guestAccount: 'Cuenta invitada',
    resetPwd: 'Restablecer contraseña',
    password: 'Contraseña',
    passwordConfirm: 'Confirma contraseña',
    newsletters: 'Suscribirse al newsletter',
    forgotBtn: '¿Olvidaste tu contraseña?',
    forgotHeader: '¿Olvidaste tu contraseña?',
    forgotExpl:
      'No te preocupes. Escribe tu email a continuación y te enviaremos un mensaje con instrucciones para recuperarla.',
    remember: '¿Recordar otra vez?',
    signUp: 'Abrir sesión',
    google: 'Usar tu cuenta de Google',
    facebook: 'Usar tu cuenta de Facebook',
    createAccount: 'Crear una cuenta',
    createsAccount: 'Crea automáticamente una cuenta vinculada a su correo electrónico',
    haveAccount: '¿Ya tienes una cuenta?',
    msgLoggedIn: 'Sesión iniciada',
    msgLoggedOut: 'Sesión cerrada',
    msgSentConfirm: 'Enviaremos un mensaje de confirmación a tu casilla de correo.',
    msgSentReset: 'Te enviaremos un email para que restablezcas tu contraseña.',
    msgPwdReset: '¡Enhorabuena! Tu contraseña ha sido actualizada.',
    msgSentConfirmNew: 'Enviaremos un email a tu nueva dirección para confirmarla.',
    msgPwdUpdated: 'Tu contraseña ha sido actualizada.',
    loginToSaveProgress: 'Debes iniciar la sesión para poder guardar tu progreso',
    actionRequiresLogin: 'Para realizar esta acción debes iniciar sesión.',
  },
  profile: {
    title: 'Tu perfil | TopLogger',
    description: 'Cambia tu perfil y personaliza tu configuración y elementos predeterminados en TopLogger',
    info: {
      changePicture: 'Cambiar foto de perfil',
      zoomToCrop: 'Acerca y arrastra para recortar',
      upload: 'Cargar',
      socialMedia: 'Redes sociales',
      changeFile: 'Cambiar archivo',
      chooseFile: 'Escoger archivo',
      firstName: 'Nombre(s)',
      lastName: 'Apellido(s)',
      male: 'Hombre',
      female: 'Mujer',
      other: 'Otro',
      country: 'País',
      city: 'Ciudad',
      dateBirth: 'Fecha de nacimiento',
      hideDateBirth: 'Ocultar fecha de nacimiento a otros usuarios',
      newsletters: 'Quiero recibir el newsletter',
      anonymous: 'Anónimo',
      length: 'Altura (cm)',
      weight: 'Peso (kg)',
      email: 'Email',
      changeMail: 'Cambiar email',
      enterNewMail: 'Por favor, ingresa tu nuevo email',
      newEmail: 'Nuevo email',
    },
    password: {
      changePwd: 'Cambiar contraseña',
      newPwd: 'Nueva contraseña',
      confirmNewPwd: 'Confirma tu nueva contraseña',
    },
    preferences: {
      preferences: 'Preferencias',
      language: 'Idioma',
      anonymousSuffix: 'Oculto en los rankings de los rocódromos',
      genderSetToast: "Para figurar en los rankings tu género se marcó como 'Hombre'.",
      anonymousSetToast:
        'Ahora figuras como usuario anónimo: para aparecer en los rankings tendrás que escoger un género.',
    },
    notifications: {
      notifications: 'Notificaciones',
      channels: 'Canales',
      push: 'Push',
      newRoutes: 'Nuevas rutas',
      newBoulders: 'Nuevos bloques',
      news: 'Novedades',
      competitions: 'Competiciones',
      restrictRange: 'Limitar rango de grados',
      onlyRoutesBetween: 'Avisarme solamente si hay rutas nuevas entre',
      onlybouldersBetween: 'Avisarme solamente si hay bloques nuevos entre',
      selectGym: 'Seleccionar un rocódromo para recibir notificaciones',
      gymsSelected: '{number} seleccionado',
      noGuarantee:
        'Aviso: las notificaciones son realizadas por los administradores de cada rocódromo; no podemos garantizar que siempre vayas a recibir noticias sobre actualizaciones.',
    },
  },
  gyms: {
    title: 'Rocódromos disponibles | TopLogger',
    description:
      'Una lista de todos los rocódromos disponibles en TopLogger. Escoge tu rocódromo para apuntarte las rutas y bloques.',
    gyms: 'Rocódromos',
    visitedGyms: 'Rocódromos visitados',
    visitedGymsSubhead: 'Los lugares donde has encadenado en los últimos seis meses',
    gymsNearby: 'Rocódromos cerca',
    selectGym: 'Selecciona tu rocódromo actual',
    goToGym: 'Ir al roco',
    gymLogo: 'Logo del roco',
    commingSoon: 'Próximamente...',
    noSwitching: 'Para cambiar de rocódromo, descárgate la aplicación gratuita.',
    notAllowed: '¡Lo sentimos! No autorizado...',
  },
  climbs: {
    title: '{routesBoulders} actualmente en {gymName} | TopLogger',
    descriptionMap: 'Un mapa interactivo que muestra todos los {routesBoulders} actualmente disponibles en {gymName}.',
    descriptionList: 'Una lista de todos los {routesBoulders} actualmente disponibles en {gymName}.',
    listView: 'Ver listado',
    mapView: 'Ver mapa',
    showList: 'Mostrar lista',
    viewMap: 'Mostrar mapa',
    new: 'Nuevo',
    guestMsg: 'Cuenta de usuario invitado.',
    groups: 'Grupos',
    walls: 'Sectores',
    filters: {
      filters: 'Filtros',
      filterBtn: 'Filtrar escaladas',
      activeMsg: 'Los filtros están activados',
      grade: 'Grado',
      starRating: 'Puntaje',
      colors: 'Colores',
      todo: 'Todo',
      almostRemoved: 'Próximo en salir',
      forChildren: 'Apto para niños',
      selectAll: 'Seleccionar todo',
      selectAllFiltered: 'Seleccionar todos los filtrados',
      selectAllInView: 'Seleccionar todos a la vista',
      deselectAll: 'Eliminar selección',
      clearFilters: 'Limpiar filtros',
    },
    group: {
      sharedWith: 'Grupo compartido con:',
      hiddenWall: '1 sector oculto por los filtros',
      hiddenWalls: '{count} sectores ocultos por los filtros',
      hiddenGroup: '1 grupo oculto por los filtros',
      hiddenGroups: '{count} ocultos por los filtros',
      hiddenRoute: '1 ruta oculta por los filtros',
      hiddenRoutes: '{count} rutas ocultas por los filtros',
      hiddenBoulder: '1 bloque oculto por los filtros',
      hiddenBoulders: '{count} bloques ocultos por los filtros',
    },
    log: {
      done: 'Hecho',
      top: 'Top',
      flash: 'Flash',
      onsight: 'A vista',
      rp: 'RP',
      fl: 'FL',
      os: 'AV',
      repeat: 'Repeticiones',
      check: 'marcar',
      tickOff: 'tachar',
      zones: 'Zonas',
      zone: 'Zona',
      myOpinion: 'Mi opinión de grado',
      tries: 'Intentos',
      repeats: 'Repeticiones',
      uncheckMsg: 'Esto quitará tu encadene de hace {timeAgo}.',
      removeAscend: 'Quitar encadene',
      project: 'Proyecto',
      getApp: {
        toastTitle: 'Nada aquí',
        toastMsg: 'Ir a toplogger.nu y descargar en tu dispositivo...',
        header: 'Esta aplicación está bloqueada',
        title: 'Instálala en tu dispositivo',
        explanation:
          'Para guardar tus encadenes, entra a toplogger.nu y descarga la aplicación <i>gratuita<i> o usa la versión en línea.',
      },
      all: {
        areYouSure: '¿Estás seguro?',
        update: 'Esto actualizará las ascensiones de {timeAgo}.',
        uncheck: 'Esto eliminará una ascensión por {routeBoulder}, incluidas las ascensiones de {timeAgo}.',
        updateAscends: 'Actualizar ascensiones',
        removeAscends: 'Eliminar ascensiones',
        logRoutes: 'Apuntarme las {count} rutas',
        logBoulders: 'Apuntarme los {count} bloques',
      },
      stats: {
        stats: 'Estadísticas',
        statistics: 'Estadísticas',
        dateSet: 'Establecer fecha',
        dateRemoved: 'Fecha eliminada',
        votesTitle: 'Votaciones de grado',
        votesEmpty: 'Aún no hay votaciones de grado...',
        votesTotal: '{votes} votos',
        ratingTitle: 'Puntaje',
        ratingEmpty: 'Aún no hay puntaje...',
        toppedHeader: 'Encadenes de',
        toppedSubhead: 'No se incluyen los usuarios anónimos',
        notToppedBoulder: 'Este Bloque aún no ha sido encadenado.',
        notToppedRoute: 'Esta Ruta aún no ha sido encadenada.',
      },
      renew: {
        renewedSoonBoulder: 'Próximo a salir',
        renewedSoonRoute: 'Próxima a salir',
        vote: 'Votar para renovar',
        unvote: 'Cancelar voto de renovación',
        voted: 'Has votado (anónimo)',
        votedMsg: 'Has votado para renovar {thisRouteBoulder}.',
        unvoted: 'Voto eliminado',
        unvotedMsg: 'Se ha eliminado tu votación por renovar {thisRouteBoulder}.',
      },
      report: {
        report: 'Informar',
        reportClimb: 'Informar problema',
        explanation: 'Algo no está bien, informar al rocódromo.',
        messageLabel: 'Mensaje al rocódromo',
        successMsg: 'Se enviará un mensaje al rocódromo.',
      },
      help: {
        header: 'Cómo apuntar los encadenes',
        buttons: {
          header: 'Tipos de encadene',
          subhead: '¿Qué debo seleccionar?',
          topExpl: 'Has encadenado.',
          flashExpl: 'Has encadenado al primer intento.',
          os: 'AV: A vista',
          osExpl: 'Has encadenado<sup>1</sup> al primer intento sin beta<sup>2</sup>.',
          fl: 'FL: Flash',
          flExpl: 'Has encadenado<sup>1</sup> al primer intento pero con beta<sup>2</sup>.',
          rp: 'RP: RedPoint',
          rpExpl: 'Has encadenado<sup>1</sup> pero no al primer intento.',
          elaboration:
            'Redpoint es cuando encadenas la ruta después de haberla ensayado durante algún tiempo y de haber estudiado sus pasos.',
          sup1: '<sup>1</sup>Has encadenado: Has llegado al top sin caer y sin colgar del arnés.',
          sup2:
            '<sup>2</sup>Beta: Toda información acerca de la ruta, ya sea contada por otros o por haberla visto escalar.',
        },
        stability: {
          header: 'Indicador de consolidación',
          explanation: 'El punto debajo del grado indica qué tan consolidado está el grado. Tiene tres valores:',
          unstableText: '<strong>Inestable</strong>: No hay votos suficientes. El grado aún puede cambiar.',
          midStableText: '<strong>Consolidándose</strong>: Hacen falta más votos pero el grado se está consolidando.',
          stableText:
            '<strong>Consolidado</strong>: Muchos usuarios votaron por este grado. Probablemente no cambiará.',
        },
        questions: {
          QgradeChange: '¿Qué pasa con mi puntaje si cambia el grado?',
          AgradeChange: 'No te preocupes: tu puntaje se ajustará al instante.',
        },
      },
    },
  },
  dashboard: {
    title: 'Tablero TopLogger de {gymName}',
    description:
      'Rankings del rocódromo, rutas y bloques nuevos, tus estadísticas personales y tu progreso en {gymName}.',
    otherUserMsg: 'Estadísticas de {userName}',
    viewClimbs: 'Ver {routesBoulders}',
    showMine: 'Ver mi gráfica',
    showDemoGraph: 'Ver gráfica de ejemplo',
    showingDemoGraph: 'Gráfica de ejemplo',
    notEnoughLogged: 'No has registrado ascensiones suficientes para mostrar una gráfica.',
    guestUser: 'Usuario invitado',
    statsLoadErrorMsg: 'No se pudieron recuperar las estadísticas de usuario.',
    whatsapp: 'WhatsApp',
    facebook: 'Facebook',
    twitter: 'Twitter',
    link: 'Link',
    score: {
      subhead: 'Estadísticas y progreso',
      countHeader: 'Conteo de {routesBoulders}',
      countHeaderExpl: 'Número de {routesBoulders} encadenados en {gymName}',
      gradeHeader: 'Grado en {routeBoulder}',
      gradeHeaderExpl:
        'Promedio de tus 10 ascensiones de mayor dificultad encadenadas en {gymName} en los últimos dos meses',
      historyHeader: 'Historia de grado de {routeBoulder}',
      historySubhead: 'Grado basado en tus encadenes en <strong>todos</strong> los rocódromos.',
      countBarText: '{count} de {total}',
      gradeBarTooltip: 'Te queda {percentLeft}% para llegar',
      effective: 'Duración',
      bonus: 'Bonus',
      points: 'Puntos',
      average: 'Promedio',
      bonusExplHeader: 'Puntaje del bonus',
      bonusExplOS: 'Si encadenas <b>a vista</b>, sumas dos grados franceses, equivalentes a 33.33 puntos.',
      bonusExplFL: 'Si encadenas al <b>flash</b>, sumas un punto francés, equivalente a 16.66 puntos.',
    },
    ranking: {
      header: 'Rankings de {gymName}',
      noName: 'Sin nombre',
      setNameMsg: 'Por favor, ingresa tu nombre para figurar en los rankings. Tu nombre no se verá en la lista.',
      anonymousMsg: 'Estás anónimo así que no figurarás en los rankings.',
      countHeader: 'Ranking de encadenes',
      countSubhead: 'Mayoría de encadenes en {gymName}',
      gradeHeader: 'Ranking de grado',
      gradeSubhead: 'Promedio de los 10 encadenes de mayor dificultad en los últimos 2 meses',
      all: 'Todo',
      tooFewRoutesMsg0: 'Aún no has escalado ninguna ruta.',
      tooFewRoutesMsg1: 'Solamente has escalado una ruta.',
      tooFewRoutesMsg: 'Solmente has escalado {count} rutas.',
      tooFewBouldersMsg0: 'Aún no has escalado ningún bloque.',
      tooFewBouldersMsg1: 'Solamente has escalado un bloque.',
      tooFewBouldersMsg: 'Solamente has escalado {count} bloques.',
      logMoreMsg: 'Apúntate {count} más para obtener un promedio de los 10 más difíciles.',
      logRoutesBtn: 'Apuntar rutas',
      logBouldersBtn: 'Apuntar bloques',
      tooFewLogsMsg: 'Basado en {count} encadenes',
    },
    oldNew: {
      header: 'Actualizaciones de {routeBoulder}',
      subhead: 'Algunas novedades acerca de {routesBoulders} en tu roco',
      lastIn: 'Más reciente',
      newClimbs: 'Nuevos {routesBoulders}',
      firstOut: 'Primero en salir',
      oldClimbs: '{routesBoulders} próximos en salir',
      daysLeft: 'Quedan {count} días',
      nothing: 'Nada...',
    },
  },
  competitions: {
    title: 'Competiciones en {gymName} | TopLogger',
    description:
      'Una lista de todas las competiciones en {gymName}. Se incluyen las competiciones pasadas con sus resultados y también las competiciones actuales.',
    compTitle: '{competitionName} en {gymName} | TopLogger',
    compDescription: "Resultados de la competición '{competitionName}' en {gymName}.",
    compClimbsTitle: "Los {routesBoulders} de '{competitionName}' | TopLogger",
    compClimbsDescMap: 'Mapa interactivo con todos los {routesBoulders} de {competitionName}.',
    compClimbsDescList: 'Una lista de todos los {routesBoulders} en {competitionName}.',
    noCompetitionsMsg: 'Actualmente no hay ninguna competición en {gymName}...',
    notParticipatingInMsg: 'Competiciones en las que aún no estás participando:',
    competitionResults: 'Resultados de la compe',
    competition: 'Competición',
    expiredComp: 'Competición finalizada',
    expiredComps: 'Competiciones finalizadas',
    expiredCompsSubhead: 'Mira los resultados',
    noMoreLogging: 'Ya no puedes apuntarte encadenes para esta competición.',
    logSubhead: 'Muestra los encadenes desde el inicio de {compName} {startDate}',
    expiredToastr: 'Competición finalizada',
    expiredToastrMsgJoin: 'Ha cerrado la inscripción, ya no puedes apuntarte',
    expiredToastrMsgQuit: 'Ha cerrado la inscripción, ya no puedes desapuntarte',
    participate: 'Participar',
    participated: 'Has participado',
    participating: 'Estás participando',
    subscribed: 'Te has inscrito',
    logClimbs: 'Apuntar encadenes',
    notStarted: 'Esta competición aún no ha comenzado',
    unranked: 'Sin puntaje',
    approvingMsg: 'Esperando respuesta del rocódromo.',
    approvingMsg2: 'Pero ya puedes comenzar a apuntar.',
    approvingToastr: 'Esperando respuesta',
    approvingToastrMsg: 'Tu solicitud debe ser autorizada por el roco.',
    approvedToastrMsg: '¡Ya puedes escalar!',
    genderRequiredMsg: 'Para participar debes seleccionar género',
    ageRequiredMsg: 'Para participar debes apuntar tu fecha de nacimiento',
    setGender: 'Seleccionar género',
    setAge: 'Apuntar fecha de nacimiento',
    unsubscribeTitle: 'Salir de {competitionName}',
    unsubscribeMsg: '¿En serio quieres salir de la compe?',
    unsubscribe: 'Salir',
    unsubscribedMsg: "Ya no estás participando en '{competitionName}'.",
    start: 'Inicio',
    deadline: 'Cierre',
    ranking: 'Ranking',
    toggleRanking: 'Ranking visible',
    delayedScoresMsg: 'Los resultados de esta competición se actualizan cada 30 segundos.',
    refresh: 'Actualizar resultados',
    switchPoule: 'Cambiar categoría',
    selectPoule: 'Seleccionar categoría',
    selectPouleExpl:
      'Esta competición está dividida en categorías. Selecciona la categoría en la que quieres participar. Luego podrás modificarla.',
    help: {
      btn: 'Ayuda',
      header: 'Acerca de las competiciones',
      generalTitle: 'Cómo funciona',
      generalExpl:
        'Una competición es un ranking basado en los encadenes desde la fecha de inicio hasta la fecha de cierre.',
      generalExpl2:
        'Si ya tenías algo hecho antes del inicio de la competición, tendrás que repetirlo para que se sume a tu puntaje. Los puntos de las ascensiones se definen en cada compe.',
      poulesTitle: 'Puntaje por categorías',
      poulesExpl:
        'Una competición puede estar separada en categorías. Esto significa que participarás en una sub-competición con escaladores de un grado similar. Cuando te inscribas, te pediremos que ingreses tu grado (redpoint): ¡porfa, no hagas trampa!',
    },
    info: {
      header: 'Normas y condiciones',
      subhead: 'Relativas a {groupName}',
      rules: 'Normas',
      pricesHeader: 'Premios',
      scoreSystem: 'Sistema de puntuación',
      points: {
        description:
          'Ranking basado en puntos. Cada encadene tiene un puntaje, que puede ser más si se encadena al primer intento.',
        points: '{points} puntos',
        bonus: '{points} puntos bonus',
      },
      pointsByGrade: {
        description:
          'Ranking basado en puntos. Cada encadene tiene un puntaje, que puede ser más si se encadena al primer intento. Los puntos dependen de la dificultad de la ruta según la tabla de equivalencias de grado. Si hay zonas, cada zona cuenta como un encadene.',
        button: 'Mostrar tabla de equivalencias',
        explanation: 'Puntos según el grado.',
        efforts:
          'Solo se usan tus mejores {efforts} intentos para calcular tu puntaje. Si hay zonas, cada zona cuenta como un intento.',
      },
      divBy: {
        description:
          'Cada top (y cada zona) suma 1000 puntos dividido por el número de participantes que la escalaron.',
        bonusPercent: '{percentage}% puntos bonus',
      },
    },
    setGrade: {
      header: 'Define tu grado para competir',
      explanation:
        'Esta competición tiene categorías donde clasificas según tu grado. Así podrás competir con escaladores con un rango de grado similar al tuyo.',
      explanation2:
        'Por favor, escoge tu grado máximo encadenado (redpoint). Lo tendremos en cuenta para determinar tu categoría:',
      minGradeExplanation: 'Has encadenado por lo menos ',
    },
  },
  gym: {
    title: '{gymName} - Información general | TopLogger',
    description: 'Contacto, horarios y mapa de {gymName}.',
    currentGym: 'Rocódromo actual:',
    contact: 'Contacto',
    phone: 'Teléfono',
    parking: 'Aparcamiento',
    website: 'Página web',
    gallery: 'Galería',
    gallerySubhead: 'Opiniones sobre el roco',
    impression: 'Opiniones',
    hours: 'Horarios',
    closed: 'Cerrado',
    Monday: 'Lunes',
    Tuesday: 'Martes',
    Wednesday: 'Miércoles',
    Thursday: 'Jueves',
    Friday: 'Viernes',
    Saturday: 'Sábado',
    Sunday: 'Domingo',
    location: 'Ubicación',
  },
  reservations: {
    title: 'Reservas para {gymName} | TopLogger ',
    description: 'Encuentra los horarios disponibles y haz una reserva para visitar {gymName}.',
    accountRequiredMsg: 'Necesita una cuenta para poder reservar una franja horaria',
    slotsHeader: 'Franjas horarias disponibles',
    selectArea: 'Seleccione su area',
    spotsLeft: 'Quedan {spots} lugares',
    dialogTitle: 'Reserva tu franja horaria',
    notAvailableYet: 'Aun no está disponible',
    available: 'Disponible',
    customerInformation: 'Información al cliente',
    associate: 'Compañero',
    addAssociate: 'Agregar asociado',
    removeAssociate: 'Quitar asociado',
    book: 'Reservar',
    myBookings: 'Mis reservas',
    expiredBookings: 'Reservas caducadas',
    lastMinuteBooking: 'Esta es una reserva de última hora',
    lastMinuteBookingExpl: 'Las reservas de última hora no cuentan para las restricciones en el número de reservas.',
    fullyBooked: 'Completamente lleno',
    cancelBooking: 'Cancelar reserva',
    bookingNumber: 'Número de reserva',
    clientNumber: 'Número de cliente',
    passwordHint: 'La contraseña específica para este intervalo de tiempo.',
    sendConfirmation: 'Enviar correo electrónico de confirmación',
    acceptContact: 'Permito que el gimnasio me contacte en caso de cambios en mi reserva',
    checkinTime: 'Hora de entrada',
    checkout: 'Revisa',
    checkOutTablet:
      'Ingrese el correo electrónico utilizado para su reserva para revisar. Todos los socios de su reserva también se verifican.',
    emails: {
      confirmation: {
        subject: 'Reserva en {gym_name} confirmada',
        confirmed: 'Reserva confirmada',
        activity_at: '{activity} en {gym_name}',
        confirmation: 'Su reserva para {area_name} en {gym_name} ha sido confirmada.',
        checkin_time: 'Hora de entrada',
        booking_number: 'Número de reserva',
        manage_online: 'Puede administrar sus reservas en línea a través de TopLogger:',
        manage_booking: 'Gestionar reserva',
        thanks: '¡Gracias!',
      },
      no_show: {
        subject: 'Reserva perdida',
        you_are_marked: 'Su reserva en {gym_name} se ha marcado como no presentación.',
        time_was: 'La hora de registro fue en {date} entre {time_start} y {time_end}.',
        cancel: 'Puede cancelar sus reservas en cualquier momento iniciando sesión en su cuenta de TopLogger.',
        questions: 'Si tiene alguna pregunta, comuníquese directamente con el centro.',
      },
      checkoutReminder: {
        subject: 'Recordatorio para revisar',
        reminder: 'Este es un recordatorio amable de {gym_name} para retirar en el mostrador.',
        time_is: 'Su hora de pago es el {date} a las {time_end}.',
      },
    },
  },
  help: {
    title: 'Ayuda | TopLogger',
    description:
      'Cómo funciona. Reglas de escalada y encadenes para apuntar tus ascensiones en la aplicación de escalada TopLogger.',
    faq: {
      header: 'FAQ',
      subhead: 'Preguntas frecuentes',
      example: 'Ejemplo',
      qGradeCalc: '¿Cómo se calcula mi grado?',
      aGradeCalc: 'Tu grado se calcula promediando tus 10 ascensiones de mayor dificultad de los últimos dos meses.',
      aGradeCalcEx1:
        "Tus diez ascensiones de mayor dificultad son cinco 6a y cinco 6a+. Es decir, tu grado está exactamente entre 6a y 6a+. Estás al 50% de consolidarte en 6a+, de modo que tu grado actual será '6a más 50%'.",
      aGradeCalcEx2:
        "Tus diez ascensiones de mayor dificultad son un 6a y nueve 6a+. Es decir, tu grado consolidado es casi 6a+: solo tienes que encadenar uno más. Estás al 90% de consolidarte en 6a+, de modo que tu grado actual será '6a más 90%'.",
      showPoints: 'Mostrar sistema de puntuación',
      qTopFlash: '¿Cuál es la diferencia entre un Top y un Flash?',
      qTopFlashRp: '¿Qué es RP, FL y AV?',
      qCareTopFl: '¿Cuál es la importancia de un Top o un Flash?',
      aCareTopFl:
        "El flash te da más puntos: encadenar un bloque al flash suma un '+' a la dificultad de los bloques en tu cálculo de grado.",
      aCareTopFlEx1:
        'Encadenaste un 6a al flash. En tu cálculo de grados esta ascensión cuenta como si hubieras encadenado un 6a+.',
      aCareTopFlEx2:
        'Encadenaste un 6c+ al flash. En tu cálculo de grados esta ascensión cuenta como si hubieras encadenado un 7a.',
      qCareTopFlRp: '¿De qué van el Redpoint, el Flash y el A vista?',
      aCareTopFlRp:
        "El flash y el a vista suman más puntos. Encadenar una ruta al flash suma un '+' a la dificultad de las rutas en tu cálculo de grado. Si encadenas una ruta a vista, sumas hasta dos '+'.",
      aCareTopFlRpEx1:
        'Encadenaste un 6a al flash. En tu cálculo de grados esta ascensión cuenta como si hubieras encadenado un 6a+.',
      aCareTopFlRpEx2:
        'Encadenaste un 6a a vista. En tu cálculo de grados esta ascensión cuenta como si hubieras encadenado un 6b.',
      aCareTopFlRpEx3:
        'Encadenaste un 6c+ al flash. En tu cálculo de grados esta ascensión cuenta como si hubieras encadenado un 7a.',
      aCareTopFlRpEx4:
        'Encadenaste un 6c+ a vista. En tu cálculo de grados esta ascensión cuenta como si hubieras encadenado un 7a+.',
      qScoreTime: '¿Cuánto tiempo contabilizarán mis ascensiones en mi ranking?',
      aScoreTimeGrade:
        'Los encadenes que se tienen en cuenta para tu ranking son los 10 de mayor dificultad en los últimos dos meses, sin importar si esas rutas o bloques ya han sido eliminados.',
      aScoreTimeCount:
        'Tu ranking de encadenes contempla los encadenes de todos los bloques y/o rutas que están actualmente en el gimnasio. Si un bloque o ruta se quita, entonces deja de contar en el ranking de encadenes.',
      qGradeStability: '¿Qué es ese punto de color?',
    },
    guide: {
      header: 'Instrucciones de escalada para principiantes',
      subhead: 'Las reglas del juego',
      intro:
        'El objetivo al hacer bloque es llegar hasta arriba (el top) por un único color de presas. Pero debes seguir algunas reglas. Aquí te las explicamos brevemente:',
      starting: 'Salida',
      startingExpl1:
        'Las manos van en las presas marcadas. Los pies van donde quieras excepto el colchón y tienes que poder mantenerte estable en esta posición antes de empezar a escalar. Es decir, no puedes salir de un salto a menos que se incluya en la descripción del bloque.',
      startingExpl2:
        'Puede que haya otras condiciones, como <i>sitstart<i>, donde tienes que salir del bloque sentado.',
      climbing: 'Escalar',
      climbingExpl: "Escalar es 'sencillo': hay que subir usando solo las presas del mismo color.",
      topping: 'Encadenar',
      toppingExpl:
        'Encadenas un bloque (o haces top) cuando tocas la última presa con ambas manos durante tres segundos.',
      logging: 'Apuntar',
      loggingExpl: 'Hay tres maneras de encadenar y apuntarte una ruta:',
      loggingOs: 'A vista (AV) <small>al primer intento y sin información</small>',
      loggingOsExpl:
        'Si encadenaste una ruta al primer intento sin que nadie te dijera cómo hacerlo, es un encadene a vista.',
      loggingFl: 'Flash (FL) <small>al primer intento y con información</small>',
      loggingFlExpl:
        "Si encadenaste una ruta al primer intento pero tenías información de cómo hacerla (o cómo no hacerla), has encadenado 'al flash'. Por ejemplo, si antes viste a alguien más hacerla o si alguien te dijo cómo iba.",
      loggingRp: 'Encadene Redpoint (RP) <small>todo lo demás</small>',
      loggingRpExpl: 'Si encadenaste un bloque o ruta pero te tomó más de un intento, es un encadene Redpoint.',
    },
  },
  contact: {
    title: 'Contacto | TopLogger',
    description: 'Contáctanos para poner Toplogger en tu roco.',
    header: 'Contacto',
    subhead: 'Pongámonos en contacto',
    wantInGym: '¿Quieres tener Toplogger en tu gimnasio?',
    sendMail: 'Envíanos un email:',
  },
  feedback: {
    title: 'Opiniones | TopLogger',
    description: 'Dinos lo que piensas de TopLogger.',
    header: 'Envíanos tu opinión',
    subhead: 'Dinos cómo mejorar',
    forGym: 'Enviar comentarios a {gymName}',
    forApp: 'Enviar comentarios sobre la aplicación',
    explanation: 'Recomendar cambios y mejoras para la aplicación o informar sobre problemas.',
    explanationGym: 'Recomienda mejoras para {gymName}, informa acerca de errores o simplemente haz una sugerencia.',
    gymFeedbackMsg:
      'En caso de que falten bloques o rutas o haya información incorrecta, por favor contacta a tu rocódromo.',
    yourFeedback: 'Tu comentario',
    yourFeedbackSub: 'Recibir respuesta',
    message: 'Mensaje',
    feedbackGym: 'Comentario del rocódromo',
    feedbackApp: 'Comentario de la aplicación',
    reply: 'Respuesta',
    retract: 'Deshacer',
    inputLabel: 'Escribe tu opinión (anónimo)',
    successMsg: '¡Genial! ¡Gracias por darnos tu opinión!',
  },
  terms: {
    title: 'Términos y condiciones | TopLogger',
    description: 'Términos y condiciones de servicio y politica de privacidad de TopLogger',
  },
  components: {
    gradesChart: {
      header: 'Tabla de equivalencias de grado',
      explanation: 'Puntos relativos a los distintos sistemas de graduación',
    },
  },
  $vuetify, // Do not translate me please :)
}
